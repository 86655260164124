<template>
    <div style="width:100%;">
        <el-main>
            <el-container>
                <el-row>
                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form :inline="true">
                            <el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width:440px;margin-right:10px;">
                                <template #prepend>
                                    <el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 220px">
                                        <el-option label="轮播图名称" value="CarouselName"></el-option>
                                        <el-option label="备注" value="RemarkInfo"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                            <el-form-item label="">
                                <el-date-picker v-model="searchStartTime" type="date" placeholder="开始日期"></el-date-picker>
                                <el-date-picker v-model="searchEndTime" type="date" placeholder="结束日期"></el-date-picker>
                            </el-form-item>
                            <slot name="searchSlot"></slot>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage">查询</el-button>
                                <el-button type="success" v-has="'Add'" icon="el-icon-edit" size="medium" @click="dialogVisible=true">新增</el-button>
                                <!-- <el-button type="warning" icon="el-icon-share" size="medium" @click="ExportExcel">导出</el-button> -->
                                <el-button type="danger" v-has="'FakeDelete'" icon="el-icon-delete" size="medium" @click="DeleteInfo">删除</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-table size="small" ref="multipleTable" :data="dataTable" style="width:99%;" :fit="true" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50"></el-table-column>
                            <el-table-column label="操作" fixed="right" header-align="center" align="center">
                                <template #default="scope">
                                    <el-button size="mini" v-has="'Update'" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" v-has="'FakeDelete'" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="CarouselName" label="轮播图名称">
                                <template #default="scope">
                                    <label>{{scope.row.CarouselName}}</label>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="FileId" label="图片路径">
                                <template #default="scope">
                                    <el-image :src="scope.row.FileUrl" :preview-src-list="[scope.row.FileUrl]" fit="fill" style="width: 90px; height: 100px"></el-image>
                                    <!-- <label>{{scope.row.FileId}}</label> -->
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="StartTime" label="开始时间">
                                <template #default="scope">
                                    <label>{{scope.row.StartTime}}</label>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="EndTime" label="结束时间">
                                <template #default="scope">
                                    <label>{{scope.row.EndTime}}</label>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="CarouselEnumType" label="图片类型">
                                <template #default="scope">
                                    <el-tag size="medium">{{scope.row.CarouselEnumType==0?"轮播图":"无"}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column sortable header-align="center" align="center" prop="RemarkInfo" label="备注">
                                <template #default="scope">
                                    <label>{{scope.row.RemarkInfo}}</label>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-container>
            <el-footer style="height:60px;position:fixed;bottom:0;width:100%;">
                <el-pagination background @size-change="SizeChange" @current-change="CurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 500, 1000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-footer>
            <el-dialog v-model="dialogVisible" title="微信配置管理" width="35%" :before-close="handleClose">
                <el-form ref="form" :model="form" status-icon label-width="120px" :rules="rules">
                    <el-form-item label="轮播图名称" prop="CarouselName">
                        <el-input v-model="form.CarouselName"></el-input>
                    </el-form-item>
                    <el-form-item label="图片路径" prop="FileId">
                        <el-upload class="avatar-uploader" :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`" :show-file-list="false" :on-success="function (res, file,fileList) { return handleAvatarSuccess(res, file,fileList)} " :before-upload="beforeAvatarUpload" :headers="headers">
                            <img v-if="form.FileId" :src="form.FileUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <!-- <el-input v-model="form.FileId"></el-input> -->
                    </el-form-item>
                    <el-form-item label="开始时间" prop="StartTime">
                        <el-date-picker v-model="form.StartTime" type="datetime"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" prop="EndTime">
                        <el-date-picker v-model="form.EndTime" type="datetime"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="图片类型" prop="CarouselEnumType">
                        <el-select v-model="form.CarouselEnumType" placeholder="请选择">
                            <el-option label="轮播图" checked value="0">
                            </el-option>
                        </el-select>
                        <!-- <el-input v-model="form.CarouselEnumType"></el-input> -->
                    </el-form-item>
                    <el-form-item label="备注" prop="RemarkInfo">
                        <el-input v-model="form.RemarkInfo"></el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">关闭</el-button>
                        <el-button type="primary" @click="btnAdd">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </el-main>
    </div>
</template>

<script>
import "@element-plus/icons";
export default {
    name: "Carousel",
    data() {
        return {
            form: {
                Id: 0,
                CarouselName: "",
                FileId: 0,
                StartTime: "",
                EndTime: "",
                CarouselEnumType: "0",
                RemarkInfo: "",
            },
            //输入验证
            rules: {
                CarouselName: [
                    { required: true, message: "轮播图名称不能为空" },
                ],
                FileId: [{ required: true, message: "图片路径不能为空" }],
                StartTime: [{ required: true, message: "开始时间不能为空" }],
                EndTime: [{ required: true, message: "结束时间不能为空" }],
                CarouselEnumType: [
                    { required: true, message: "图片类型不能为空" },
                ],
            },
            total: 400, //数据总量
            pagesize: 100, //分页大小
            currentPage: 1, //当前页数
            keyword: "", //关键字
            checkInput: "", //搜索内容
            searchStartTime: "", //搜索开始时间
            searchEndTime: "", //搜索结束时间
            dataTableSearchList: [], //列筛选结果
            dataTable: [], //显示数据
            multipleSelection: [], //多选结果
            dialogVisible: false, //是否显示弹出框
            headers: "", //请求headers
        };
    },
    mounted() {
        const that = this;
        this.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            let adminInfo = this.$TOOL.data.get("adminInfo");
            this.headers = {
                Authorization: "Bearer " + adminInfo.JwtToken,
            };
            window.onresize = () => {
                return (() => {
                    let heightStyle = window.innerHeight - 230;
                    //that.Height = heightStyle;
                })();
            };
            that.SearchPage();
        });
    },
    methods: {
        //分页搜索
        SearchPage() {
            var that = this;
            var json = {
                searchValue: "",
                pageNo: that.currentPage,
                pageSize: that.pagesize,
                searchBeginTime: that.searchStartTime,
                searchEndTime: that.searchEndTime,
                sortField: "",
                sortOrder: "",
                descStr: "",
            };
            if (that.checkInput.length > 0 && that.keyword != "") {
                var searchParameters = [],
                    input = that.keyword;
                for (let index = 0; index < that.checkInput.length; index++) {
                    const element = that.checkInput[index];
                    var searchParametersItem = {
                        field: element,
                        op: 8,
                        value: input,
                        orGroup: "",
                    };
                    searchParameters.push(searchParametersItem);
                }
                json.searchParameters = searchParameters;
            }
            that.$API.Carousel.QueryByPage.post(json).then((res) => {
                that.dataTable = res.Rows;
                that.total = res.TotalRows;
            });
        },
        //重置数据
        resetForm() {
            this.form = {
                Id: 0,
                CarouselName: "",
                FileId: 0,
                StartTime: "",
                EndTime: "",
                CarouselEnumType: "0",
                RemarkInfo: "",
            };
        },
        //导出
        ExportExcel() {},
        //删除
        DeleteInfo() {
            var Ids = [];
            var that = this;
            var json = {};
            for (const key in this.multipleSelection) {
                if (Object.hasOwnProperty.call(this.multipleSelection, key)) {
                    const element = this.multipleSelection[key];
                    Ids.push(element.Id);
                }
            }
            this.$API.Carousel.FakeDelete.delete(Ids).then((res) => {
                if (res.Code == 204) {
                    that.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.SearchPage();
                }
            });
        },
        //列筛选
        filterFunHandle(type, currentItem, index) {
            if (type === "allchecked") {
                // 全选
                var tmp = [];

                this.dataTableSearchList = tmp;
            } else if (type === "cancel") {
                // 取消全选
                this.dataTableSearchList = []; // 复选框置为空，全部不选择
            }
        },
        //多选结果
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //编辑
        handleEdit(index, row) {
            var that = this;
            this.$API.Carousel.QueryById.get(row.Id).then((res) => {
                that.form = res;
                console.log(res);
                that.form.CarouselEnumType=that.form.CarouselEnumType+"";
                that.dialogVisible = true;
            });
        },
        //删除
        handleDelete(index, row) {
            var Ids = [];
            Ids.push(row.Id);
            var that = this;
            this.$API.Carousel.FakeDelete.delete(Ids).then((res) => {
                if (res.Code == 204) {
                    that.$notify({
                        title: "成功",
                        message: "操作成功！",
                        type: "success",
                    });
                    this.SearchPage();
                }
            });
        },
        //分页数修改
        SizeChange(val) {
            this.pagesize = val;
            this.SearchPage();
        },
        //当前页跳转
        CurrentChange(val) {
            this.currentPage = val;
            this.SearchPage();
        },
        //关闭弹出框
        handleClose() {
            this.dialogVisible = false;
            this.resetForm();
        },
        //新增或者修改
        btnAdd() {
            var that = this;
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    that.$API.Carousel.AddOrUpdate.post(that.form).then(
                        (res) => {
                            if (res.Code == 204) {
                                that.$notify({
                                    title: "成功",
                                    message: "操作成功！",
                                    type: "success",
                                });
                                that.handleClose();
                                that.SearchPage();
                            }
                        }
                    );
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //图片上传成功
        handleAvatarSuccess(res, file, fileList, obj) {
            console.log(res);
            this.form.FileUrl = URL.createObjectURL(file.raw);
            //this.imageUrl = URL.createObjectURL(file.raw);
            this.form.FileId = res.Data.Id;
            //this.$emit("handleAvatarSuccess", res);
        },
        //图片上传判断
        beforeAvatarUpload(file) {
            const fileType = file.type;
            const isImg = !(
                fileType != ".jpg" &&
                fileType != ".JPG" &&
                fileType != "image/jpeg" &&
                fileType != ".png" &&
                fileType != ".PNG" &&
                fileType != "image/png" &&
                fileType != ".gif" &&
                fileType != ".GIF" &&
                fileType != "image/gif"
            );
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isImg) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isImg && isLt2M;
        },
    },
};
</script>

<style>
</style>